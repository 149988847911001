import './App.css';
import 'animate.css';
import 'animate.css/animate.compat.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollTop from './general/ScrollTop';
import Header from './general/Header';
import Footer from './general/Footer';
import Home from './components/Home';
import PageNotFound from './components/PageNotFound';
import ComingSoon from './components/ComingSoon';
import About from './components/About';
import Contact from './components/Contact';
import Careers from './components/Careers';


const App = () => {
  return (
    <BrowserRouter>
    <ScrollTop />
    <Header />
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/about' element={<About />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='/careers' element={<Careers />} />
      <Route exact path="*" element={<PageNotFound />} />
      <Route exact path="/comingsoon" element={<ComingSoon />} />
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;

