import React from 'react'
import SubHeader from '../general/SubHeader';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const Careers = () => {
  return (
    <>
      <SubHeader message='Careers' />

      {/* Careers section */}
      <section className='my-5'>
        <div className='container'>

          <h2 className='fw-bold mb-4'>Opportunity at ResQ</h2>
          <hr className='hr-line' />

          <p>Join our internship program and be a part of ResQ. Check the recent Job openings and our team will revert you shortly.</p>


          <ScrollAnimation animateIn='fadeInUp' className='row mt-5'>

            <div className='col-lg-6 col-md-6 col-12 my-2'>
              <div className='career-card bg-light'>
                <i class="fa-solid fa-graduation-cap fs-1"></i>
                <p className='fs-5 fw-bold mt-4'>Our Internship</p>
                <p>Assist in product development, marketing, and customer support for ResQ, focused on innovating safety solutions.</p>
                <button className='banner-btn bg-danger text-light animate__animated animate__pulse animate__infinite'>
                  <Link to='https://docs.google.com/forms/d/e/1FAIpQLSef0ROM7QSqWBgAgvLjyYNSUTCLyHeVRvFyz0DAXiRU5buM_A/viewform' target='_blank' className='banner-link text-light'>Apply Now!</Link></button>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-12 my-2'>
              <div className='career-card bg-light'>
                <i class="fa-solid fa-graduation-cap fs-1"></i>
                <p className='fs-5 fw-bold mt-4'>Job Openings</p>
                <p>Join ResQ to gain hands-on experience in a dynamic, innovative environment. Secure your future with us.</p>
                <button className='banner-btn bg-danger text-light animate__animated animate__pulse animate__infinite'>
                  <Link to='https://docs.google.com/forms/d/e/1FAIpQLSef0ROM7QSqWBgAgvLjyYNSUTCLyHeVRvFyz0DAXiRU5buM_A/viewform' target='_blank' className='banner-link text-light'>Apply Now!</Link></button>
              </div>
            </div>

          </ScrollAnimation>
          {/* row ends */}


        </div>
      </section>

      {/* Careers section ends */}

    </>
  )
}

export default Careers
