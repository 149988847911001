import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png';
import ScrollToTop from 'scroll-to-top-react';

const Footer = () => {
    return (
        <>

            <ScrollToTop smooth className='scroll-to-top' displayType='htmlArrow' />

            <footer className='footer-bg'>
                <div className='container'>

                    <div className='row'>

                        <div className='col-lg-4 col-md-6 col-12 my-2'>
                            <img src={Logo} className='footer-logo' alt='footer logo' />
                            <p className='mt-3'>At ResQ, we are dedicated to developing innovative emergency alert solutions that ensure the safety and well-being of individuals
                                in critical situations. Your generous contribution will help us continue our mission and make a positive impact in the community.</p>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-2'>
                            <p className='fs-5 fw-bold'>Important Links</p>
                            <hr className='hr-line' />

                            <Link className='footer-link' to='/'>Home</Link><br /><br />
                            <Link className='footer-link' to='/about'>About</Link><br /><br />
                            <Link className='footer-link' to='/tutorials'>Tutorials</Link><br /><br />
                            <Link className='footer-link' to='/careers'>Careers</Link><br /><br />
                            <Link className='footer-link' to='/contact'>Contact</Link>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12 my-2'>
                            <p className='fs-5 fw-bold'>ResQ Shield App</p>
                            <hr className='hr-line' />

                            <p className='my-4'>Download our Application & shield your safety with us. We are here to protect you.</p>

                            <button className='banner-btn'>Download App</button>

                            <div className='mt-4'>
                                <Link to='https://www.facebook.com/share/14SnBENtKn/?mibextid=LQQJ4d' target='_blank'>
                                    <i className="fa-brands cursor-pointer fa-facebook contact-icon me-3"></i></Link>
                                <Link to='https://www.instagram.com/connect.resq?igsh=MThkNTNqcDNuZ2RwbA==' target='_blank'>
                                    <i className="fa-brands cursor-pointer fa-instagram contact-icon me-3"></i></Link>
                                    <Link to='https://www.youtube.com/@ResQInfratech' target='_blank'>
                                    <i className="fa-brands cursor-pointer fa-youtube contact-icon me-3"></i></Link>
                                <Link to='https://www.linkedin.com/company/resq-infratech-pvt-ltd/' target='_blank'>
                                    <i className="fa-brands cursor-pointer fa-linkedin-in contact-icon"></i></Link>
                            </div>

                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </footer>

            {/* Top Section */}
            {/* <section className='bg-light rounded-bottom-4 py-1'>
        <div className='container-fluid px-4'>

          <div className='d-lg-flex d-md-flex d-block justify-content-between'>

            <div className='my-1 header-text'>
              <i className="fa-solid fa-phone"></i> Call us: 7275786295 &nbsp;&nbsp; <i className="fa-solid fa-envelope"></i> resqinfra@gmail.com
            </div>

            <div className='my-1 header-text'>
              <Link to='https://www.facebook.com/share/14SnBENtKn/?mibextid=LQQJ4d' target='_blank'>
              <i className="fa-brands cursor-pointer fa-facebook text-black me-3"></i></Link>
              <Link to='https://www.instagram.com/connect.resq?igsh=MThkNTNqcDNuZ2RwbA==' target='_blank'>
              <i className="fa-brands cursor-pointer fa-instagram text-black me-3"></i></Link>
              <Link to='https://www.linkedin.com/company/resq-infratech-pvt-ltd/' target='_blank'>
              <i className="fa-brands cursor-pointer fa-linkedin-in text-black"></i></Link>
              <span> &nbsp;&nbsp;|&nbsp;&nbsp; 24/7 Emergency Service</span>
            </div>

          </div>

        </div>
      </section> */}
            {/* Top Section ends */}

            <div className='text-center para-text bg-light py-3 fw-bold'>
                Copyright &copy; ResQ Infratech. All Rights Reserved. Developed By
                <Link className='text-dark' to='https://dectwinservices.com' target='_blank'> Dectwin Services Pvt Ltd.</Link>
            </div>
        </>
    )
}

export default Footer
