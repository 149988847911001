import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = (props) => {
  return (
    <>
      <section className='subheader-bg'>
        <div className='container'>
            <p className='fs-2 fw-bold'>{props.message}</p>
            <Link to='/' className='footer-link'>Home</Link> &nbsp;&nbsp;|&nbsp;&nbsp; <span>{props.message}</span>
        </div>
      </section>
    </>
  )
}

export default SubHeader
