import React from 'react'
import FloatingSec1 from '../assets/floating-sec1.png';
import FloatingSec2 from '../assets/floating-sec2.webp';
import FloatingSec3 from '../assets/floating-sec3.png';
import FloatingSec4 from '../assets/floating-sec4.webp';
import AboutInfo from '../general/AboutInfo';
import Faq from '../general/Faq';
import HeaderBanner from '../assets/header-banner.png';
import BannerImg1 from '../assets/banner-img1.jpeg';
import BannerImg2 from '../assets/banner-img2.jpeg';
import BannerImg3 from '../assets/banner-img3.jpeg';

import WhyChoose from '../general/WhyChoose';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  return (
    <>
      {/* Banner Section */}
      <section className='banner-bg'>
        <div className='container'>

          <div className='row'>

            <div className='col-lg-6 col-12 my-3'>
              <h1 className='banner-heading'>ResQ <i className="fa-solid fa-shield-halved text-danger"></i><br />We Shield Your Safety</h1>
              <p className='mt-5'>ResQ serves a platform dedicated to providing women with enhanced safety and peace of mind in their daily lives.
                Our mission is to create a secure environment where women can confidently navigate the world, knowing that help is just a click away.</p>

              <button className='banner-btn my-2' onClick={() => navigate('/contact')}>Get In Touch</button>
            </div>

            <div className='col-lg-4 col-12 ms-auto'>
              <img src={HeaderBanner} className='img-fluid' alt='Banner right' />
            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
      {/* Banner Section ends */}


      {/* Floating Cards Section */}
      <section className='floating-card-sec container mb-5'>

        <div className='row text-center'>

          <div className='col-lg-3 col-md-3 col-6 my-2'>
            <div className='floating-card animate__animated animate__pulse animate__infinite'>
              <img src={FloatingSec1} className='img-fluid' alt='Floating Card' />
              <p className='floating-card-name'>Real Time Location Sharing</p>
            </div>
          </div>

          <div className='col-lg-3 col-md-3 col-6 my-2'>
            <div className='floating-card animate__animated animate__pulse animate__infinite'>
              <img src={FloatingSec2} className='img-fluid' alt='Floating Card' />
              <p className='floating-card-name'>Alert Notifications</p>
            </div>
          </div>

          <div className='col-lg-3 col-md-3 col-6 my-2'>
            <div className='floating-card animate__animated animate__pulse animate__infinite'>
              <img src={FloatingSec3} className='img-fluid' alt='Floating Card' />
              <p className='floating-card-name'>24/7 Emergency Service</p>
            </div>
          </div>

          <div className='col-lg-3 col-md-3 col-6 my-2'>
            <div className='floating-card animate__animated animate__pulse animate__infinite'>
              <img src={FloatingSec4} className='img-fluid' alt='Floating Card' />
              <p className='floating-card-name'>Online Assistance</p>
            </div>
          </div>

        </div>
        {/* row ends */}

      </section>
      {/* Floating Cards Section ends */}


{/* Slider Section */}
<div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={BannerImg3} className="d-block w-100" alt="Banner 1" />
    </div>
    <div className="carousel-item">
      <img src={BannerImg2} className="d-block w-100" alt="Banner 2" />
    </div>
    <div className="carousel-item">
      <img src={BannerImg1} className="d-block w-100" alt="Banner 3" />
    </div>
  </div>
</div>
{/* Slider Section ends */}


      <AboutInfo />

      {/* Parallax Section */}
      <section className='parallax-bg'>
        <div className='container'>

          <div className='row'>

            <div className='col-lg-6 col-12'>
              <p className='fs-4 fw-bold'>Urgent Cause</p>
              <p className='banner-heading'>Prioritising Women's Safety</p>
              <p>Don't miss this chance to be at the forefront of innovation and contribute to create a safer tomorrow. Get in touch with us
                now and discover how ResQ is redefining the way we respond to emergencies.</p>
            </div>


            <div className='col-lg-4 col-12 my-2 ms-auto'>
              <div className='parallax-right'>
                <i className="fa-solid fa-rainbow fs-1"></i>
                <p className='fs-4 fw-bold mt-3'>Available For you</p>
                <hr className='hr-line bg-white' />
                <p>Click on the button below & send your query to us. Our team will be in touch with you shortly.</p>
                <button className='banner-btn my-3 animate__animated animate__pulse animate__infinite'>
                  <Link to='https://wa.me/7275786295?text=Hello%20I%20want%20know%20about%20ResQ%20Shield.' target='_blank' className='banner-link'>Connect with Us</Link></button>
              </div>
            </div>

          </div>
          {/* row ends */}


        </div>
      </section>
      {/* Parallax Section ends */}

      <WhyChoose />

      <Faq />

    </>
  )
}


export default Home
