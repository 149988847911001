import React from 'react'
import AboutInfo from '../general/AboutInfo';
import SubHeader from '../general/SubHeader';

const About = () => {
  return (
    <>
      <SubHeader message='About Us' />
      <AboutInfo />

      <section className='about-bg py-5'>
        <div className='container'>

          <p className='text-danger fs-5 fw-bold my-0'>Our Mission</p>
          <p className='banner-heading'>Available to ResQ You</p>

          <div className='row'>
            <div className='col-lg-6 col-md-6 col-12 my-2'>
              <div className='mission-card'>
                <p className='fs-4 fw-bold'>Our Vision</p>
                <hr className='hr-line' />
                <p className='text-muted'>Welcome to ResQ, the emergency alert system designed to keep you safe and connected in critical situations. We understand that emergencies can happen anytime, anywhere, which is why we have developed a powerful and intuitive platform to provide you with immediate assistance at the touch of a button. Whether you're in need of urgent medical attention, require assistance from the police, ResQ has got you covered. Our advanced technology enables us to quickly identify your location, connect you with the nearest responders, and ensure that help reaches you in the shortest possible time. With ResQ by your side, you can have peace of mind knowing that help is just a click away. Join our community today and experience the reliability, speed, and efficiency of ResQ in safeguarding your well-being.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='mission-card'>
              <i class="fa-solid fa-bullseye fs-1 text-danger"></i>
              <p className='fs-4 fw-bold mt-4'>Our Mission</p>
                <hr className='hr-line' />
                <p className='text-muted'>We are dedicated to be the leading platform that connects individuals in need with the nearest emergency service providers. We strive to save lives, minimize response time, and create a safer community for everyone.</p>
              </div>
            </div>

          </div>
          {/* inner row ends */}


        </div>
      </section>
    </>
  )
}

export default About
