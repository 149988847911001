import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Function to handle navbar collapse on link click
  const handleLinkClick = () => {
    setIsCollapsed(true); // Collapse the navbar after link click
  };

  return (
    <>

      {/* Navbar section */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top py-lg-1 py-md-1 py-0">
        <div className="container-fluid px-4">
          <Link className="navbar-brand" to="/"><img src={Logo} className='header-logo' alt='header logo' /></Link>
          <button className="navbar-toggler border-0" onClick={() => setIsCollapsed(!isCollapsed)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse justify-content-center ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item my-3">
                <NavLink className="nav-text" activeClassName="active" onClick={handleLinkClick} to="/">Home</NavLink>
              </li>
              <li className="nav-item my-3">
                <NavLink className="nav-text" activeClassName="active" onClick={handleLinkClick} to="/about">About Us</NavLink>
              </li>
              <li className="nav-item my-3">
                <NavLink className="nav-text" activeClassName="active" onClick={handleLinkClick} to="/comingsoon">Tutorials</NavLink>
              </li>
              <li className="nav-item my-3">
                <NavLink className="nav-text" activeClassName="active" onClick={handleLinkClick} to="/careers">Careers</NavLink>
              </li>
              <li className="nav-item my-3">
                <NavLink className="nav-text" activeClassName="active" onClick={handleLinkClick} to="/contact">Contact Us</NavLink>
              </li>

              <button className='banner-btn bg-danger text-light d-lg-none d-md-block d-block my-3' onClick={() => navigate('/comingsoon')}>Download App</button>

            </ul>
          </div>
          {/* navbar list ends */}

          <button className='banner-btn bg-danger text-light d-lg-block d-md-none d-none' onClick={() => navigate('/comingsoon')}>Download App</button>

        </div>
      </nav>
      {/* Navbar section ends */}

    </>
  )
}

export default Header;
