import React from 'react'
import WhyChooseImg from '../assets/why-choose.jpg';

const WhyChoose = () => {
  return (
    <>
      <section>

          <div className='row'>

            <div className='col-lg-6 col-12'>
              <img src={WhyChooseImg} className='img-fluid' alt='Choose ResQ' />
            </div>

            <div className='col-lg-6 col-12 my-5 px-4'>
              <h2 className='fw-bold mb-4'>Why Choose ResQ?</h2>
              <hr className='hr-line' />

              <p className='my-5'>ResQ offers a range of services, including medical emergency response, fire department dispatch, roadside assistance, and more. Our user-friendly interface and advanced technology enable us to deliver help rapidly and efficiently.</p>

              <h6><i className="me-2 text-danger fa-solid fa-kit-medical"></i>Fast and reliable emergency response<br /><br />
              <i className="me-2 text-danger fa-solid fa-kit-medical"></i>Trained and experienced professionals<br /><br />
              <i className="me-2 text-danger fa-solid fa-kit-medical"></i>24/7 availability<br /><br />
              <i className="me-2 text-danger fa-solid fa-kit-medical"></i>Seamless integration with emergency service providers<br /><br />
              <i className="me-2 text-danger fa-solid fa-kit-medical"></i>User-friendly interface</h6>

            </div>

          </div>
          {/* row ends */}

      </section>
    </>
  )
}

export default WhyChoose
