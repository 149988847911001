import React from 'react'
import LostImg from '../assets/coming-soon.png';
import { useNavigate } from 'react-router-dom';


const ComingSoon = () => {

  const navigate = useNavigate();

  return (
    <>
    <section className='container text-center my-5'>
      <img src={LostImg} style={{height: '22em'}} className='img-fluid animate__animated animate__pulse animate__infinite' alt='Page not found' />
      <p>This page is under progress. We'll bring it soon for you.</p>
      <button className='banner-btn' onClick={() => navigate('/')}>Click Here</button>
    </section>
    </>
  )
}

export default ComingSoon;
