import React from 'react'
import LostImg from '../assets/pagenotfound.webp';
import { useNavigate } from 'react-router-dom';


const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <>
    <section className='container text-center my-5'>
      <img src={LostImg} className='img-fluid animate__animated animate__pulse animate__infinite' alt='Page not found' />
      <p>Oops Page not Found! Looks you are lost somewhere. Let's get you back to the site now.</p>
      <button className='banner-btn' onClick={() => navigate('/')}>Click Here</button>
    </section>
    </>
  )
}

export default PageNotFound;
